var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"informationsGeneraux"},[_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"12"}},[_c('b-card',[_c('b-card-header',{staticClass:"d-flex justify-content-between"},[_c('h3',[_vm._v("RENSEIGNEMENTS D'EXPLOITATION DU MOIS")])]),_c('hr'),_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('h3',[_vm._v("1- Voyages organisés et croisières tout compris")])]),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"table-responsive text-center mt-1"},[_c('table',{staticClass:"table table-striped table-bordered"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(" Voyages Internes ")]),_c('th',[_vm._v("Nombres")])])]),_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Voyages d'entrée sans frais ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                              _vm.infos.voyagesInternes.sansFrais
                            ),callback:function ($$v) {_vm.$set(_vm.infos.voyagesInternes, "sansFrais", _vm._n($$v))},expression:"\n                              infos.voyagesInternes.sansFrais\n                            "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Voyages d'entrée avec frais ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                              _vm.infos.voyagesInternes.avecFrais
                            ),callback:function ($$v) {_vm.$set(_vm.infos.voyagesInternes, "avecFrais", _vm._n($$v))},expression:"\n                              infos.voyagesInternes.avecFrais\n                            "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Total ")]),_c('td',[_vm._v(" "+_vm._s(_vm.normalizeNumericValue(_vm.infos.voyagesInternes.sansFrais + _vm.infos.voyagesInternes.avecFrais))+" ")])])])])])]),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"table-responsive text-center mt-1"},[_c('table',{staticClass:"table table-striped table-bordered"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(" Voyages Internationaux ")]),_c('th',[_vm._v("Nombres")])])]),_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Voyages d'entrée sans frais ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                              _vm.infos.voyagesInternationaux.entreeSansFrais
                            ),callback:function ($$v) {_vm.$set(_vm.infos.voyagesInternationaux, "entreeSansFrais", _vm._n($$v))},expression:"\n                              infos.voyagesInternationaux.entreeSansFrais\n                            "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Voyages d'entrée avec frais ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                              _vm.infos.voyagesInternationaux.entreeAvecFrais
                            ),callback:function ($$v) {_vm.$set(_vm.infos.voyagesInternationaux, "entreeAvecFrais", _vm._n($$v))},expression:"\n                              infos.voyagesInternationaux.entreeAvecFrais\n                            "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Voyages de sortie sans frais ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                              _vm.infos.voyagesInternationaux.sortieSansFrais
                            ),callback:function ($$v) {_vm.$set(_vm.infos.voyagesInternationaux, "sortieSansFrais", _vm._n($$v))},expression:"\n                              infos.voyagesInternationaux.sortieSansFrais\n                            "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Voyages de sortie avec frais ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                              _vm.infos.voyagesInternationaux.sortieAvecFrais
                            ),callback:function ($$v) {_vm.$set(_vm.infos.voyagesInternationaux, "sortieAvecFrais", _vm._n($$v))},expression:"\n                              infos.voyagesInternationaux.sortieAvecFrais\n                            "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Total ")]),_c('td',[_vm._v(" "+_vm._s(_vm.normalizeNumericValue(_vm.infos.voyagesInternationaux.entreeSansFrais) + _vm.normalizeNumericValue(_vm.infos.voyagesInternationaux.entreeAvecFrais) + _vm.normalizeNumericValue(_vm.infos.voyagesInternationaux.sortieSansFrais) + _vm.normalizeNumericValue(_vm.infos.voyagesInternationaux.sortieAvecFrais))+" ")])])])])])])],1)],1)],1)],1)],1),(_vm.showEditionButtons)?_c('b-row',[_c('b-col',{staticClass:"my-2 text-right",attrs:{"cols":"12"}},[_c('div',{},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Modifier ")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }