<template>
  <validation-observer ref="informationsGeneraux">
    <b-row>
      <b-col
        lg="12"
        class="pb-1"
      >
        <!-- RENSEIGNEMENTS GENERAUX  --> 
        <!-- {{ infos }} -->
        <div>
           <b-card id="renseignementsgeneraux">
              <b-card-header class="d-flex justify-content-between">
                <h3>RENSEIGNEMENTS GÉNÉRAUX</h3>
              </b-card-header>
              <hr>
              <b-card-body>
                <b-row>
                  <!-- Partie gauche -->
                  <b-col md="6">
                    <b-form-group
                      label-cols-md="3"
                      label="Nom Établissement"
                    >
                      <b-form-input
                        v-model="infos.nomEtablissement"
                        type="text"
                        disabled
                      />
                      <!-- <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="infos.nomEtablissement"
                          type="text"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="mauvaisEtablissement"
                          class="text-danger"
                        >Mauvais établissement associé à l'agrément</small>
                      </validation-provider> -->
                    </b-form-group>
                    <b-form-group
                      label-cols-md="3"
                      label="Prénoms Promoteur"
                    >
                      <b-form-input
                        v-model="infos.prenomsPromoteur"
                        type="text"
                        disabled
                      />
                      <!-- <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="infos.nomPromoteur"
                          type="text"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider> -->
                    </b-form-group>
                    <b-form-group
                      label-cols-lg="3"
                      label="Nationalité Promoteur"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <v-select
                          v-model="infos.nationalitePromoteur"
                          type="text"
                          :options="pays"
                          label="name"
                          placeholder="Nationalité  du Promoteur"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Partie droite -->
                  <b-col md="6">
                    <b-form-group
                      label-cols-md="3"
                      label="Numéro IFU"
                    >
                      <b-form-input
                        v-model="infos.numIFU"
                        type="text"
                        v-digits-only="{length: 16}"
                        disabled
                      />
                      <!-- <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="infos.numIFU"
                          type="text"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="invalideIfu"
                          class="text-danger"
                        >Numéro IFU Invalide</small>
                      </validation-provider> -->
                    </b-form-group>
                    <b-form-group
                      label-cols-md="3"
                      label="Nom Promoteur"
                    >
                      <b-form-input
                        v-model="infos.nomPromoteur"
                        type="text"
                        disabled
                      />
                      <!-- <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="infos.nomPromoteur"
                          type="text"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider> -->
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- adresse complète -->
                <b-form-group
                  class="mt-2"
                  label-cols-lg="3"
                  label="Adresse Complète Établissement "
                >
                  <b-form-input
                    v-model="infos.adresseComplete"
                    type="text"
                    disabled
                  />
                  <!-- <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-form-input
                      v-model="infos.adresseComplete"
                      type="text"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
                </b-form-group>
                <!-- telephone -->
                <b-row class="mt-2">
                  <b-col md="12">
                    <div>Numéros de Téléphone</div>
                  </b-col>
                  <b-col
                    class="mt-1"
                    md="4"
                  >
                    <b-form-group
                      label-cols-md="3"
                      label="Numéro 1"
                    >
                      <!-- <validation-provider
                        v-slot="{ errors }"
                        rules="required|length:8"
                      > -->
                        <b-form-input
                          v-model="infos.telephone1"
                          type="text"
                                          v-phonenumber-field

                        />
                        <!-- <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider> -->
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="mt-1"
                    md="4"
                  >
                    <b-form-group
                      label-cols-md="3"
                      label="Numéro 2"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="length:8"
                      >
                        <b-form-input
                          v-model="infos.telephone2"
                          type="text"
                                          v-phonenumber-field

                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="mt-1"
                    md="4"
                  >
                    <b-form-group
                      label-cols-md="3"
                      label="Numéro 3"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="length:8"
                      >
                        <b-form-input
                          v-model="infos.telephone3"
                          type="text"
                                          v-phonenumber-field

                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- type transport -->
                <!-- <b-row class="mt-2">
                  <b-col md="12"> Type Établissement </b-col>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-radio-group
                      v-model="infos.typeEtablissement"
                      :options="typesEts"
                      class="demo-inline-spacing mb-1 ml-1"
                      value-field="value"
                      text-field="text"
                      disabled-field="disabled"
                    />
                    <small class="text-danger ml-1">{{ errors[0] }}</small>
                  </validation-provider>

                  <b-col
                    v-if="infos.typeEtablissement == 'Autres'"
                    class=""
                    md="12"
                  >
                    <b-form-group
                      label-cols="5"
                      label-cols-lg="3"
                      label="Type Établissement"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="infos.typeEtablissementAutres"
                          type="text"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row> -->
                <!-- agrément -->
                <b-row class="mt-2">
                  <b-col md="12">
                    <div>Agrément du ministère du tourisme ?</div></b-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-form-radio-group
                      v-model="infos.agrementMinistere"
                      :options="agrementOptions"
                      class="demo-inline-spacing mb-1 ml-1"
                      value-field="value"
                      text-field="text"
                      disabled-field="disabled"
                    />
                    <small class="text-danger ml-1">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-col
                    v-if="infos.agrementMinistere == 'oui'"
                    class="mt-1"
                    md="8"
                  >
                    <b-form-group
                      label-cols="5"
                      label-cols-lg="3"
                      label="Référence Agrément"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="infos.referenceAgrement"
                          type="text"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="invalideAgrement"
                          class="text-danger"
                        >Agrément invalide</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-else
                    class="mt-1"
                    md="6"
                  />
                </b-row>
                <hr>
                <!-- réservé à l'admin -->
                <!-- <b-form-group
                  class="mt-2"
                  label-cols-lg="3"
                  label="Réservé à l'administration "
                >
                  <b-form-input
                    v-model="infos.resereAdministration"
                    type="text"
                  />
                </b-form-group> -->
              </b-card-body>
            </b-card>
        </div>
      </b-col>
    </b-row>

    <!-- Editions button -->
    <b-row v-if="showEditionButtons">
      <b-col
        cols="12"
        class="my-2 text-right"
      >
        <div class="">
          <b-button
            variant="outline-secondary"
            @click="onCancel"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm"
          >
            Modifier
          </b-button>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import vSelect from 'vue-select'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import utilsService from '@/services/utils/utils.service'
// imports for validation
// const store = require('store')

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BCardText,
    // BForm,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition], //
  data() {
    return {
      email,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      annees: [
        { name: '2019' },
        { name: '2020' },
        { name: '2021' },
        { name: '2022' },
        { name: '2023' },
      ],
      mois: [],
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      pays: [],
      types: [
        { text: 'Agence de voyage', value: 'Agence de voyage' },
        { text: 'Tour Operator', value: 'Tour Operator' },
        { text: 'Agence réceptive', value: 'Agence réceptive' },
      ],
      agrements: [
        { text: 'Non', value: 'non' },
        { text: 'Oui', value: 'oui' },
      ],

      infos: {
        nomEtablissement: '',
        numIFU: '',
        nomPromoteur: '',
        prenomsPromoteur: '',
        nationalitePromoteur: '',
        adresseComplete: '',
        telephone1: '',
        telephone2: '',
        telephone3: '',
        typeEtablissement: '',
        agrementMinistere: '',
        referenceAgrement: '',
      },
      invalideAgrement: false,
      agrementOptions: ['non', 'oui'],

    }
  },
  computed: {
    // ...mapState('params', {
    //   listPays: 'listPays',
    //   listMois: 'listMois',
    //   listAnnees: 'listAnnees',
    // }),
  },
  props: {
    inputData: {
      type: Object,
      default: () => null,
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.pays = utilsService.getListCountries();
    this.mois = utilsService.getListMonths()
    this.annees = utilsService.getListYears()

    if (this.inputData) {
      this.infos = this.inputData
    } else if (this.$route.params.data) {
      this.infos = this.$route.params.data
    } else {
      // this.infos = this.validOutput
    }


  },
  methods: {
    ...mapActions('params', {
      fetchAnnees: 'fetchAnnees',
    }),
    onCancel() {
      this.$emit('onCancel', {})
    },
    submitForm() {
      // this.$emit('onFormSuccess', this.infos)
      this.$refs.informationsGeneraux.validate().then(success => {
        if (success) {
          if (this.infos) {
            if (this.infos.annee.name) this.infos.annee = this.infos.annee.name
            if (this.infos.mois.name) this.infos.mois = this.infos.mois.name
            if (this.infos.departement.name) this.infos.departement = this.infos.departement.name
            if (this.infos.commune.name) this.infos.commune = this.infos.commune.name
            if (this.infos.arrondissement.name) this.infos.arrondissement = this.infos.arrondissement.name
            if (this.infos.nationalitePromoteur.name) this.infos.nationalitePromoteur = this.infos.nationalitePromoteur.name

            if (this.infos.telephone2 == '') delete this.infos.telephone2
            if (this.infos.telephone3 == '') delete this.infos.telephone3
            if (this.infos.agrementMinistere == 'non') this.infos.referenceAgrement = ''
          }

          if (this.inputData) {
            this.$emit('onFormSuccess', this.infos)
          } else {
            this.$emit('onFormSucess', {
              routerParams: { preview: true, data: this.infos },
            })
          }

        }
      })
    },
    loadDepartments() {
      axios
        .get(
          'https://bj-decoupage-territorial.herokuapp.com/api/v1/departments',
        )
        .then(response => {
          console.log(response.data.departments)
          this.departements = response.data.departments
        })
    },
    loadCommunes() {
      if (this.infos.departement != '') {
        axios
          .get(
            `https://bj-decoupage-territorial.herokuapp.com/api/v1/departments/${this.infos.departement.name}/towns`,
          )
          .then(response => {
            this.communes = response.data.towns
            // console.log('communes: ', this.communes)
            return this.communes
          })
          .catch(error => console.log(error))
      } else {
        axios
          .get('https://bj-decoupage-territorial.herokuapp.com/api/v1/towns')
          .then(response => {
            this.communes = null
            this.communes = response.data.towns
            // console.log('communes: ', this.communes)
            return this.communes
          })
          .catch(error => console.log(error))
      }
    },
    loadArrondissement() {
      if (this.infos.commune != '') {
        axios
          .get(
            `https://bj-decoupage-territorial.herokuapp.com/api/v1/towns/${this.infos.commune.name}/districts`,
          )
          .then(response => {
            // console.log('no empty arrondissements: ', this.arrondissements)
            this.arrondissements = response.data.districts
            return this.arrondissements
          })
          .catch(error => console.log(error))
      } else {
        axios
          .get(
            'https://bj-decoupage-territorial.herokuapp.com/api/v1/districts',
          )
          .then(response => {
            // console.log('empty arrondissements: ', this.arrondissements)
            this.arrondissements = null
            this.arrondissements = response.data.districts
            // console.log('arrondissements: ', this.arrondissements)
            return this.arrondissements
          })
          .catch(error => console.log(error))
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/themes/bordered-layout.scss';
</style>
