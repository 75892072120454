<template>
  <validation-observer ref="informationsGeneraux">
    <b-row>
      <b-col lg="12" class="pb-2">
        <!-- EMPLOIS  -->
        <div>
          <b-card id="emplois">
            <b-card-header class="d-flex justify-content-between">
              <h3>EMPLOIS</h3>
            </b-card-header>
            <hr />
            <b-card-body>
              <!-- employés non salariés -->
              <div class="table-responsive text-center mt-3">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" rowspan="2">
                        Nombre d'employés non salariés
                      </th>
                      <th scope="col" colspan="2">Hommes</th>
                      <th scope="col" colspan="2">Femmes</th>
                      <th scope="col" colspan="2">Total</th>
                    </tr>
                    <tr>
                      <th scope="col">Temps plein</th>
                      <th scope="col">Temps partiel</th>
                      <th scope="col">Temps plein</th>
                      <th scope="col">Temps partiel</th>
                      <th scope="col">Temps plein</th>
                      <th scope="col">Temps partiel</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Nationaux</th>
                      <td>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model.number="
                              infos.employesNonSalaries.hommeTempspleinNationaux
                            "
                            v-digits-only="{ length: 16 }"
                            type="number"
                            min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model.number="
                              infos.employesNonSalaries
                                .hommeTempspartielNationaux
                            "
                            v-digits-only="{ length: 16 }"
                            type="number"
                            min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model.number="
                              infos.employesNonSalaries.femmeTempspleinNationaux
                            "
                            v-digits-only="{ length: 16 }"
                            type="number"
                            min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model.number="
                              infos.employesNonSalaries
                                .femmeTempspartielNationaux
                            "
                            v-digits-only="{ length: 16 }"
                            type="number"
                            min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        {{
                          normalizeNumericValue(
                            infos.employesNonSalaries.hommeTempspleinNationaux
                          ) +
                          normalizeNumericValue(
                            infos.employesNonSalaries.femmeTempspleinNationaux
                          )
                        }}
                      </td>
                      <td>
                        {{
                          normalizeNumericValue(
                            infos.employesNonSalaries.hommeTempspartielNationaux
                          ) +
                          normalizeNumericValue(
                            infos.employesNonSalaries.femmeTempspartielNationaux
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Étrangers</th>
                      <td>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model.number="
                              infos.employesNonSalaries.hommeTempspleinEtrangers
                            "
                            v-digits-only="{ length: 16 }"
                            type="number"
                            min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model.number="
                              infos.employesNonSalaries
                                .hommeTempspartielEtrangers
                            "
                            v-digits-only="{ length: 16 }"
                            type="number"
                            min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model.number="
                              infos.employesNonSalaries.femmeTempspleinEtrangers
                            "
                            v-digits-only="{ length: 16 }"
                            type="number"
                            min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model.number="
                              infos.employesNonSalaries
                                .femmeTempspartielEtrangers
                            "
                            v-digits-only="{ length: 16 }"
                            type="number"
                            min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        {{
                          normalizeNumericValue(
                            infos.employesNonSalaries.hommeTempspleinEtrangers
                          ) +
                          normalizeNumericValue(
                            infos.employesNonSalaries.femmeTempspleinEtrangers
                          )
                        }}
                      </td>
                      <td>
                        {{
                          normalizeNumericValue(
                            infos.employesNonSalaries.hommeTempspartielEtrangers
                          ) +
                          normalizeNumericValue(
                            infos.employesNonSalaries.femmeTempspartielEtrangers
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Total</th>
                      <td>
                        {{
                          normalizeNumericValue(
                            infos.employesNonSalaries.hommeTempspleinNationaux
                          ) +
                          normalizeNumericValue(
                            infos.employesNonSalaries.hommeTempspleinEtrangers
                          )
                        }}
                      </td>
                      <td>
                        {{
                          normalizeNumericValue(
                            infos.employesNonSalaries.hommeTempspartielNationaux
                          ) +
                          normalizeNumericValue(
                            infos.employesNonSalaries.hommeTempspartielEtrangers
                          )
                        }}
                      </td>
                      <td>
                        {{
                          normalizeNumericValue(
                            infos.employesNonSalaries.femmeTempspleinNationaux
                          ) +
                          normalizeNumericValue(
                            infos.employesNonSalaries.femmeTempspleinEtrangers
                          )
                        }}
                      </td>
                      <td>
                        {{
                          normalizeNumericValue(
                            infos.employesNonSalaries.femmeTempspartielNationaux
                          ) +
                          normalizeNumericValue(
                            infos.employesNonSalaries.femmeTempspartielEtrangers
                          )
                        }}
                      </td>
                      <td>
                        {{
                          normalizeNumericValue(
                            infos.employesNonSalaries.hommeTempspleinNationaux
                          ) +
                          normalizeNumericValue(
                            infos.employesNonSalaries.femmeTempspleinNationaux +
                              infos.employesNonSalaries.hommeTempspleinEtrangers
                          ) +
                          normalizeNumericValue(
                            infos.employesNonSalaries.femmeTempspleinEtrangers
                          )
                        }}
                      </td>
                      <td>
                        {{
                          normalizeNumericValue(
                            infos.employesNonSalaries.hommeTempspartielNationaux
                          ) +
                          normalizeNumericValue(
                            infos.employesNonSalaries.femmeTempspartielNationaux
                          ) +
                          normalizeNumericValue(
                            infos.employesNonSalaries.hommeTempspartielEtrangers
                          ) +
                          normalizeNumericValue(
                            infos.employesNonSalaries.femmeTempspartielEtrangers
                          )
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <!-- Editions button -->
    <b-row v-if="showEditionButtons">
      <b-col cols="12" class="my-2 text-right">
        <div class="">
          <b-button variant="outline-secondary" @click="onCancel">
            Cancel
          </b-button>
          <b-button variant="primary" @click="submitForm"> Modifier </b-button>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { mapActions, mapState } from "vuex";
import vSelect from "vue-select";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
// imports for validation
// const store = require('store')

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BCardText,
    // BForm,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition], //
  data() {
    return {
      email,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      annees: [
        { name: "2019" },
        { name: "2020" },
        { name: "2021" },
        { name: "2022" },
        { name: "2023" },
      ],
      mois: [],
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      pays: [],
      types: [
        { text: "Agence de voyage", value: "Agence de voyage" },
        { text: "Tour Operator", value: "Tour Operator" },
        { text: "Agence réceptive", value: "Agence réceptive" },
      ],
      agrements: [
        { text: "Non", value: "Non" },
        { text: "Oui", value: "Oui" },
      ],

      infos: {
        annee: "",
        mois: "",
        departement: "",
        commune: "",
        arrondissement: "",
        quartier: "",
        nomEtablissement: "",
        numIFU: "",
        nomPromoteur: "",
        nationalitePromoteur: "",
        adresseComplete: "",
        telephone1: "",
        telephone2: "",
        telephone3: "",
        typeEtablissement: "",
        agrementMinistere: "",
        referenceAgrement: "",
        // employés
        employesSalaries: {
          hommeTempspleinNationaux: null,
          hommeTempspartielNationaux: null,
          hommeTempspleinEtrangers: null,
          hommeTempspartielEtrangers: null,
          femmeTempspleinNationaux: null,
          femmeTempspartielNationaux: null,
          femmeTempspleinEtrangers: null,
          femmeTempspartielEtrangers: null,
        },
        nouveauEmplois: {
          hommes: null,
          femmes: null,
        },
        employesNonSalaries: {
          hommeTempspleinNationaux: null,
          hommeTempspartielNationaux: null,
          hommeTempspleinEtrangers: null,
          hommeTempspartielEtrangers: null,
          femmeTempspleinNationaux: null,
          femmeTempspartielNationaux: null,
          femmeTempspleinEtrangers: null,
          femmeTempspartielEtrangers: null,
        },
        // renseignement exploitation
        voyagesInternes: {
          sansFrais: null,
          avecFrais: null,
        },
        voyagesInternationaux: {
          entreeSansFrais: null,
          entreeAvecFrais: null,
          sortieSansFrais: null,
          sortieAvecFrais: null,
        },
        recettesBrutes: {
          billetterie: null,
          voyagesOrganises: null,
          croisieresToutCompris: null,
          autresProduits: null,
        },
        commissions: {
          billetterie: null,
          voyagesOrganises: null,
          croisieresToutCompris: null,
          hebergement: null,
          locationAutomobile: null,
          primesAssurance: null,
          autresCommissions: null,
        },
        chargesSalariales: null,
        autresDepense: null,
      },
    };
  },
  computed: {
    ...mapState("params", {
      listPays: "listPays",
      listMois: "listMois",
      listAnnees: "listAnnees",
    }),
  },
  props: {
    inputData: {
      type: Object,
      default: () => null,
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // this.pays = this.listPays
    // this.mois = this.listMois
    // this.annees = this.listAnnees

    if (this.inputData) {
      this.infos = this.inputData;
    } else if (this.$route.params.data) {
      this.infos = this.$route.params.data;
    } else {
      // this.infos = this.validOutput
    }
  },
  methods: {
    ...mapActions("params", {
      fetchAnnees: "fetchAnnees",
    }),
    onCancel() {
      this.$emit("onCancel", {});
    },
    submitForm() {
      // this.$emit('onFormSuccess', this.infos)
      this.$refs.informationsGeneraux.validate().then((success) => {
        if (success) {
          if (this.infos) {
            if (this.infos.annee.name) this.infos.annee = this.infos.annee.name;
            if (this.infos.mois.name) this.infos.mois = this.infos.mois.name;
            if (this.infos.departement.name)
              this.infos.departement = this.infos.departement.name;
            if (this.infos.commune.name)
              this.infos.commune = this.infos.commune.name;
            if (this.infos.arrondissement.name)
              this.infos.arrondissement = this.infos.arrondissement.name;
            if (this.infos.nationalitePromoteur.name)
              this.infos.nationalitePromoteur =
                this.infos.nationalitePromoteur.name;

            if (this.infos.telephone2 == "") delete this.infos.telephone2;
            if (this.infos.telephone3 == "") delete this.infos.telephone3;
            if (this.infos.agrementMinistere == "non")
              delete this.infos.referenceAgrement;
          }

          if (this.inputData) {
            this.$emit("onFormSuccess", this.infos);
          } else {
            this.$emit("onFormSucess", {
              routerParams: { preview: true, data: this.infos },
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/themes/bordered-layout.scss";
</style>
