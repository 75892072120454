<template>
  <validation-observer ref="informationsGeneraux">
    <b-row>
      <b-col
        lg="12"
        class="pb-2"
      >
        <b-card>
          <b-card-header class="d-flex justify-content-between">
            <h3>RENSEIGNEMENTS D'EXPLOITATION DU MOIS</h3>
          </b-card-header>
          <hr>
          <b-card-body>
            <!-- Commission totales réalisées -->
            <b-row>
              <b-col
                md="12"
                class="mt-2"
              >
                <h3>3- Commissions totales réalisées</h3>
              </b-col>
              <!-- Partie gauche -->
              <b-col md="12">
                <div class="table-responsive text-center mt-1">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" />
                        <th>Montants</th>
                        <th>Pourcentages</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          Billetterie
                        </th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="infos.commissions.billetterie"
                              v-digits-only="{length: 16}"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{
                              errors[0]
                            }}</small>
                          </validation-provider>
                        </td>
                        <td v-if="infos.commissions.billetterie > 0">
                          {{
                            (normalizeNumericValue(infos.commissions.billetterie) * 100) /
                              (normalizeNumericValue(infos.commissions.billetterie) +
                                normalizeNumericValue(infos.commissions.voyagesOrganises)
                                +
                                normalizeNumericValue(infos.commissions.croisieresToutCompris) +
                                normalizeNumericValue(infos.commissions.hebergement)
                                +
                                normalizeNumericValue(infos.commissions.locationAutomobile) +
                                normalizeNumericValue(infos.commissions.primesAssurance)
                                +
                                normalizeNumericValue(infos.commissions.autresCommissions))
                          }}
                          %
                        </td>
                        <td v-else>
                          0
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Voyages Organisés
                        </th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.commissions.voyagesOrganises
                              "
                              v-digits-only="{length: 16}"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{
                              errors[0]
                            }}</small>
                          </validation-provider>
                        </td>
                        <td v-if="infos.commissions.voyagesOrganises > 0">
                          {{
                            (normalizeNumericValue(infos.commissions.voyagesOrganises) * 100) /
                              (normalizeNumericValue(infos.commissions.billetterie) +
                                normalizeNumericValue(infos.commissions.voyagesOrganises) +
                                normalizeNumericValue(infos.commissions.croisieresToutCompris) +
                                normalizeNumericValue(infos.commissions.hebergement )+
                                normalizeNumericValue(infos.commissions.locationAutomobile) +
                                normalizeNumericValue(infos.commissions.primesAssurance) +
                                normalizeNumericValue(infos.commissions.autresCommissions))
                          }}
                          %
                        </td>
                        <td v-else>
                          0
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Croisières Tout Compris
                        </th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.commissions.croisieresToutCompris
                              "
                              v-digits-only="{length: 16}"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{
                              errors[0]
                            }}</small>
                          </validation-provider>
                        </td>
                        <td
                          v-if="infos.commissions.croisieresToutCompris > 0"
                        >
                          {{
                            (normalizeNumericValue(infos.commissions.croisieresToutCompris) *
                              100) /
                              (normalizeNumericValue(infos.commissions.billetterie) +
                                normalizeNumericValue(infos.commissions.voyagesOrganises) +
                                normalizeNumericValue(infos.commissions.croisieresToutCompris) +
                                normalizeNumericValue(infos.commissions.hebergement) +
                                normalizeNumericValue(infos.commissions.locationAutomobile) +
                                normalizeNumericValue(infos.commissions.primesAssurance) +
                                normalizeNumericValue(infos.commissions.autresCommissions))
                          }}
                          %
                        </td>
                        <td v-else>
                          0
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Hébergements (Prière d'exculre les commissions sur
                          des hébergements faisant partie de voyages
                          organisés ou de croisières)
                        </th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="infos.commissions.hebergement"
                              v-digits-only="{length: 16}"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{
                              errors[0]
                            }}</small>
                          </validation-provider>
                        </td>
                        <td v-if="infos.commissions.hebergement > 0">
                          {{
                            (normalizeNumericValue(infos.commissions.hebergement) * 100) /
                              (normalizeNumericValue(infos.commissions.billetterie) +
                                normalizeNumericValue(infos.commissions.voyagesOrganises) +
                                normalizeNumericValue(infos.commissions.croisieresToutCompris) +
                                normalizeNumericValue(infos.commissions.hebergement )+
                                normalizeNumericValue(infos.commissions.locationAutomobile) +
                                normalizeNumericValue(infos.commissions.primesAssurance) +
                                normalizeNumericValue(infos.commissions.autresCommissions))
                          }}
                          %
                        </td>
                        <td v-else>
                          0
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Commissions sur location d'automobiles (Prière
                          d'exculre les commissions sur des locations
                          faisant faisant partie de voyages organisés ou de
                          croisières)
                        </th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.commissions.locationAutomobile
                              "
                              v-digits-only="{length: 16}"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{
                              errors[0]
                            }}</small>
                          </validation-provider>
                        </td>
                        <td v-if="infos.commissions.locationAutomobile > 0">
                          {{
                            (normalizeNumericValue(infos.commissions.locationAutomobile) * 100) /
                              (normalizeNumericValue(infos.commissions.billetterie )+
                                normalizeNumericValue( infos.commissions.voyagesOrganises) +
                                normalizeNumericValue(infos.commissions.croisieresToutCompris )+
                                normalizeNumericValue(infos.commissions.hebergement )+
                                normalizeNumericValue(infos.commissions.locationAutomobile )+
                                normalizeNumericValue(infos.commissions.primesAssurance) +
                                normalizeNumericValue(infos.commissions.autresCommissions))
                          }}
                          %
                        </td>
                        <td v-else>
                          0
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Commission sur primes d'assurances (telles que
                          assurance voyage, assurance sur les bagages et
                          assurance-annulation, etc)
                        </th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.commissions.primesAssurance
                              "
                              v-digits-only="{length: 16}"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{
                              errors[0]
                            }}</small>
                          </validation-provider>
                        </td>
                        <td v-if="infos.commissions.primesAssurance > 0">
                          {{
                            (normalizeNumericValue(infos.commissions.primesAssurance) * 100) /
                              (normalizeNumericValue(infos.commissions.billetterie) +
                                normalizeNumericValue(infos.commissions.voyagesOrganises) +
                                normalizeNumericValue(infos.commissions.croisieresToutCompris) +
                                normalizeNumericValue(infos.commissions.hebergement) +
                                normalizeNumericValue(infos.commissions.locationAutomobile) +
                                normalizeNumericValue(infos.commissions.primesAssurance) +
                                normalizeNumericValue(infos.commissions.autresCommissions))
                          }}
                          %
                        </td>
                        <td v-else>
                          0
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Autres Commisions (telles que les attractions, les
                          chèques de voyage, etc) Veuillez préciser
                        </th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.commissions.autresCommissions
                              "
                              v-digits-only="{length: 16}"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{
                              errors[0]
                            }}</small>
                          </validation-provider>
                        </td>
                        <td v-if="infos.commissions.autresCommissions > 0">
                          {{
                            (normalizeNumericValue(infos.commissions.autresCommissions) * 100) /
                              (normalizeNumericValue(infos.commissions.billetterie )+
                                normalizeNumericValue(infos.commissions.voyagesOrganises) +
                                normalizeNumericValue(infos.commissions.croisieresToutCompris )+
                                normalizeNumericValue(infos.commissions.hebergement) +
                                normalizeNumericValue(infos.commissions.locationAutomobile )+
                                normalizeNumericValue(infos.commissions.primesAssurance) +
                                normalizeNumericValue(infos.commissions.autresCommissions))
                          }}
                          %
                        </td>
                        <td v-else>
                          0
                        </td>
                      </tr>
                      <!-- total -->
                      <tr>
                        <th scope="row">
                          Recettes Brutes Totales
                        </th>
                        <td>
                          {{
                            normalizeNumericValue(infos.commissions.billetterie )+
                              normalizeNumericValue( infos.commissions.voyagesOrganises) +
                              normalizeNumericValue(infos.commissions.croisieresToutCompris )+
                              normalizeNumericValue(infos.commissions.hebergement) +
                              normalizeNumericValue(infos.commissions.locationAutomobile )+
                              normalizeNumericValue(infos.commissions.primesAssurance) +
                              normalizeNumericValue(infos.commissions.autresCommissions)
                          }}
                        </td>
                        <td
                          v-if="
                            infos.commissions.billetterie > 0 ||
                              infos.commissions.voyagesOrganises > 0 ||
                              infos.commissions.croisieresToutCompris > 0 ||
                              infos.commissions.hebergement > 0 ||
                              infos.commissions.locationAutomobile > 0 ||
                              infos.commissions.primesAssurance > 0 ||
                              infos.commissions.autresCommissions
                          "
                        >
                          100 %
                        </td>
                        <td v-else>
                          0 %
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
            <!-- charge salariales  -->
            <b-form-group
              class="mt-2"
              label-cols-lg="3"
              label="Charges salariales (FCFA)"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <b-form-input
                  v-model.number="infos.chargesSalariales"
                  v-digits-only="{length: 16}"
                  type="number"
                  min="0"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- autres charges  -->
            <b-form-group
              class="mt-2"
              label-cols-lg="3"
              label="Autres Dépenses (FCFA)"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <b-form-input
                  v-model.number="infos.autresDepense"
                  v-digits-only="{length: 16}"
                  type="number"
                  min="0"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-card-body></b-card>
      </b-col>
    </b-row>

    <!-- Editions button -->
    <b-row v-if="showEditionButtons">
      <b-col
        cols="12"
        class="my-2 text-right"
      >
        <div class="">
          <b-button
            variant="outline-secondary"
            @click="onCancel"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm"
          >
            Modifier
          </b-button>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import vSelect from 'vue-select'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// imports for validation
// const store = require('store')

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BCardText,
    // BForm,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition], //
  data() {
    return {
      email,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      annees: [
        { name: '2019' },
        { name: '2020' },
        { name: '2021' },
        { name: '2022' },
        { name: '2023' },
      ],
      mois: [],
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      pays: [],
      types: [
        { text: 'Agence de voyage', value: 'Agence de voyage' },
        { text: 'Tour Operator', value: 'Tour Operator' },
        { text: 'Agence réceptive', value: 'Agence réceptive' },
      ],
      agrements: [
        { text: 'Non', value: 'Non' },
        { text: 'Oui', value: 'Oui' },
      ],

      infos: {
        annee: '',
        mois: '',
        departement: '',
        commune: '',
        arrondissement: '',
        quartier: '',
        nomEtablissement: '',
        numIFU: '',
        nomPromoteur: '',
        nationalitePromoteur: '',
        adresseComplete: '',
        telephone1: '',
        telephone2: '',
        telephone3: '',
        typeEtablissement: '',
        agrementMinistere: '',
        referenceAgrement: '',
        // employés
        employesSalaries: {
          hommeTempspleinNationaux: null,
          hommeTempspartielNationaux: null,
          hommeTempspleinEtrangers: null,
          hommeTempspartielEtrangers: null,
          femmeTempspleinNationaux: null,
          femmeTempspartielNationaux: null,
          femmeTempspleinEtrangers: null,
          femmeTempspartielEtrangers: null,
        },
        nouveauEmplois: {
          hommes: null,
          femmes: null,
        },
        employesNonSalaries: {
          hommeTempspleinNationaux: null,
          hommeTempspartielNationaux: null,
          hommeTempspleinEtrangers: null,
          hommeTempspartielEtrangers: null,
          femmeTempspleinNationaux: null,
          femmeTempspartielNationaux: null,
          femmeTempspleinEtrangers: null,
          femmeTempspartielEtrangers: null,
        },
        // renseignement exploitation
        voyagesInternes: {
          sansFrais: null,
          avecFrais: null,
        },
        voyagesInternationaux: {
          entreeSansFrais: null,
          entreeAvecFrais: null,
          sortieSansFrais: null,
          sortieAvecFrais: null,
        },
        recettesBrutes: {
          billetterie: null,
          voyagesOrganises: null,
          croisieresToutCompris: null,
          autresProduits: null,
        },
        commissions: {
          billetterie: null,
          voyagesOrganises: null,
          croisieresToutCompris: null,
          hebergement: null,
          locationAutomobile: null,
          primesAssurance: null,
          autresCommissions: null,
        },
        chargesSalariales: null,
        autresDepense: null,
      },
    }
  },
  computed: {
    ...mapState('params', {
      listPays: 'listPays',
      listMois: 'listMois',
      listAnnees: 'listAnnees',
    }),
  },
  props: {
    inputData: {
      type: Object,
      default: () => null,
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // this.pays = this.listPays
    // this.mois = this.listMois
    // this.annees = this.listAnnees
    

    // this.loadQuartier();
    if (this.inputData) {
      this.infos = this.inputData
    } else if (this.$route.params.data) {
      this.infos = this.$route.params.data
    } else {
      // this.infos = this.validOutput
    }
  },
  methods: {
    ...mapActions('params', {
      fetchAnnees: 'fetchAnnees',
    }),
    onCancel() {
      this.$emit('onCancel', {})
    },
    submitForm() {
      // this.$emit('onFormSuccess', this.infos)
      this.$refs.informationsGeneraux.validate().then(success => {
        if (success) {
          if (this.infos) {
            if (this.infos.annee.name) this.infos.annee = this.infos.annee.name
            if (this.infos.mois.name) this.infos.mois = this.infos.mois.name
            if (this.infos.departement.name) this.infos.departement = this.infos.departement.name
            if (this.infos.commune.name) this.infos.commune = this.infos.commune.name
            if (this.infos.arrondissement.name) this.infos.arrondissement = this.infos.arrondissement.name
            if (this.infos.nationalitePromoteur.name) this.infos.nationalitePromoteur = this.infos.nationalitePromoteur.name

            if (this.infos.telephone2 == '') delete this.infos.telephone2
            if (this.infos.telephone3 == '') delete this.infos.telephone3
            if (this.infos.agrementMinistere == 'non') delete this.infos.referenceAgrement
          }

          if (this.inputData) {
            this.$emit('onFormSuccess', this.infos)
          } else {
            this.$emit('onFormSucess', {
              routerParams: { preview: true, data: this.infos },
            })
          }
        }
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/base/themes/bordered-layout.scss';
</style>
