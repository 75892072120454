var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"informationsGeneraux"},[_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"12"}},[_c('b-card',[_c('b-card-header',{staticClass:"d-flex justify-content-between"},[_c('h3',[_vm._v("RENSEIGNEMENTS D'EXPLOITATION DU MOIS")])]),_c('hr'),_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('h3',[_vm._v("3- Commissions totales réalisées")])]),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"table-responsive text-center mt-1"},[_c('table',{staticClass:"table table-striped table-bordered"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}}),_c('th',[_vm._v("Montants")]),_c('th',[_vm._v("Pourcentages")])])]),_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Billetterie ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(_vm.infos.commissions.billetterie),callback:function ($$v) {_vm.$set(_vm.infos.commissions, "billetterie", _vm._n($$v))},expression:"infos.commissions.billetterie"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.infos.commissions.billetterie > 0)?_c('td',[_vm._v(" "+_vm._s((_vm.normalizeNumericValue(_vm.infos.commissions.billetterie) * 100) / (_vm.normalizeNumericValue(_vm.infos.commissions.billetterie) + _vm.normalizeNumericValue(_vm.infos.commissions.voyagesOrganises) + _vm.normalizeNumericValue(_vm.infos.commissions.croisieresToutCompris) + _vm.normalizeNumericValue(_vm.infos.commissions.hebergement) + _vm.normalizeNumericValue(_vm.infos.commissions.locationAutomobile) + _vm.normalizeNumericValue(_vm.infos.commissions.primesAssurance) + _vm.normalizeNumericValue(_vm.infos.commissions.autresCommissions)))+" % ")]):_c('td',[_vm._v(" 0 ")])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Voyages Organisés ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                              _vm.infos.commissions.voyagesOrganises
                            ),callback:function ($$v) {_vm.$set(_vm.infos.commissions, "voyagesOrganises", _vm._n($$v))},expression:"\n                              infos.commissions.voyagesOrganises\n                            "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.infos.commissions.voyagesOrganises > 0)?_c('td',[_vm._v(" "+_vm._s((_vm.normalizeNumericValue(_vm.infos.commissions.voyagesOrganises) * 100) / (_vm.normalizeNumericValue(_vm.infos.commissions.billetterie) + _vm.normalizeNumericValue(_vm.infos.commissions.voyagesOrganises) + _vm.normalizeNumericValue(_vm.infos.commissions.croisieresToutCompris) + _vm.normalizeNumericValue(_vm.infos.commissions.hebergement )+ _vm.normalizeNumericValue(_vm.infos.commissions.locationAutomobile) + _vm.normalizeNumericValue(_vm.infos.commissions.primesAssurance) + _vm.normalizeNumericValue(_vm.infos.commissions.autresCommissions)))+" % ")]):_c('td',[_vm._v(" 0 ")])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Croisières Tout Compris ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                              _vm.infos.commissions.croisieresToutCompris
                            ),callback:function ($$v) {_vm.$set(_vm.infos.commissions, "croisieresToutCompris", _vm._n($$v))},expression:"\n                              infos.commissions.croisieresToutCompris\n                            "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.infos.commissions.croisieresToutCompris > 0)?_c('td',[_vm._v(" "+_vm._s((_vm.normalizeNumericValue(_vm.infos.commissions.croisieresToutCompris) * 100) / (_vm.normalizeNumericValue(_vm.infos.commissions.billetterie) + _vm.normalizeNumericValue(_vm.infos.commissions.voyagesOrganises) + _vm.normalizeNumericValue(_vm.infos.commissions.croisieresToutCompris) + _vm.normalizeNumericValue(_vm.infos.commissions.hebergement) + _vm.normalizeNumericValue(_vm.infos.commissions.locationAutomobile) + _vm.normalizeNumericValue(_vm.infos.commissions.primesAssurance) + _vm.normalizeNumericValue(_vm.infos.commissions.autresCommissions)))+" % ")]):_c('td',[_vm._v(" 0 ")])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Hébergements (Prière d'exculre les commissions sur des hébergements faisant partie de voyages organisés ou de croisières) ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(_vm.infos.commissions.hebergement),callback:function ($$v) {_vm.$set(_vm.infos.commissions, "hebergement", _vm._n($$v))},expression:"infos.commissions.hebergement"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.infos.commissions.hebergement > 0)?_c('td',[_vm._v(" "+_vm._s((_vm.normalizeNumericValue(_vm.infos.commissions.hebergement) * 100) / (_vm.normalizeNumericValue(_vm.infos.commissions.billetterie) + _vm.normalizeNumericValue(_vm.infos.commissions.voyagesOrganises) + _vm.normalizeNumericValue(_vm.infos.commissions.croisieresToutCompris) + _vm.normalizeNumericValue(_vm.infos.commissions.hebergement )+ _vm.normalizeNumericValue(_vm.infos.commissions.locationAutomobile) + _vm.normalizeNumericValue(_vm.infos.commissions.primesAssurance) + _vm.normalizeNumericValue(_vm.infos.commissions.autresCommissions)))+" % ")]):_c('td',[_vm._v(" 0 ")])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Commissions sur location d'automobiles (Prière d'exculre les commissions sur des locations faisant faisant partie de voyages organisés ou de croisières) ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                              _vm.infos.commissions.locationAutomobile
                            ),callback:function ($$v) {_vm.$set(_vm.infos.commissions, "locationAutomobile", _vm._n($$v))},expression:"\n                              infos.commissions.locationAutomobile\n                            "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.infos.commissions.locationAutomobile > 0)?_c('td',[_vm._v(" "+_vm._s((_vm.normalizeNumericValue(_vm.infos.commissions.locationAutomobile) * 100) / (_vm.normalizeNumericValue(_vm.infos.commissions.billetterie )+ _vm.normalizeNumericValue( _vm.infos.commissions.voyagesOrganises) + _vm.normalizeNumericValue(_vm.infos.commissions.croisieresToutCompris )+ _vm.normalizeNumericValue(_vm.infos.commissions.hebergement )+ _vm.normalizeNumericValue(_vm.infos.commissions.locationAutomobile )+ _vm.normalizeNumericValue(_vm.infos.commissions.primesAssurance) + _vm.normalizeNumericValue(_vm.infos.commissions.autresCommissions)))+" % ")]):_c('td',[_vm._v(" 0 ")])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Commission sur primes d'assurances (telles que assurance voyage, assurance sur les bagages et assurance-annulation, etc) ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                              _vm.infos.commissions.primesAssurance
                            ),callback:function ($$v) {_vm.$set(_vm.infos.commissions, "primesAssurance", _vm._n($$v))},expression:"\n                              infos.commissions.primesAssurance\n                            "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.infos.commissions.primesAssurance > 0)?_c('td',[_vm._v(" "+_vm._s((_vm.normalizeNumericValue(_vm.infos.commissions.primesAssurance) * 100) / (_vm.normalizeNumericValue(_vm.infos.commissions.billetterie) + _vm.normalizeNumericValue(_vm.infos.commissions.voyagesOrganises) + _vm.normalizeNumericValue(_vm.infos.commissions.croisieresToutCompris) + _vm.normalizeNumericValue(_vm.infos.commissions.hebergement) + _vm.normalizeNumericValue(_vm.infos.commissions.locationAutomobile) + _vm.normalizeNumericValue(_vm.infos.commissions.primesAssurance) + _vm.normalizeNumericValue(_vm.infos.commissions.autresCommissions)))+" % ")]):_c('td',[_vm._v(" 0 ")])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Autres Commisions (telles que les attractions, les chèques de voyage, etc) Veuillez préciser ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                              _vm.infos.commissions.autresCommissions
                            ),callback:function ($$v) {_vm.$set(_vm.infos.commissions, "autresCommissions", _vm._n($$v))},expression:"\n                              infos.commissions.autresCommissions\n                            "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.infos.commissions.autresCommissions > 0)?_c('td',[_vm._v(" "+_vm._s((_vm.normalizeNumericValue(_vm.infos.commissions.autresCommissions) * 100) / (_vm.normalizeNumericValue(_vm.infos.commissions.billetterie )+ _vm.normalizeNumericValue(_vm.infos.commissions.voyagesOrganises) + _vm.normalizeNumericValue(_vm.infos.commissions.croisieresToutCompris )+ _vm.normalizeNumericValue(_vm.infos.commissions.hebergement) + _vm.normalizeNumericValue(_vm.infos.commissions.locationAutomobile )+ _vm.normalizeNumericValue(_vm.infos.commissions.primesAssurance) + _vm.normalizeNumericValue(_vm.infos.commissions.autresCommissions)))+" % ")]):_c('td',[_vm._v(" 0 ")])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Recettes Brutes Totales ")]),_c('td',[_vm._v(" "+_vm._s(_vm.normalizeNumericValue(_vm.infos.commissions.billetterie )+ _vm.normalizeNumericValue( _vm.infos.commissions.voyagesOrganises) + _vm.normalizeNumericValue(_vm.infos.commissions.croisieresToutCompris )+ _vm.normalizeNumericValue(_vm.infos.commissions.hebergement) + _vm.normalizeNumericValue(_vm.infos.commissions.locationAutomobile )+ _vm.normalizeNumericValue(_vm.infos.commissions.primesAssurance) + _vm.normalizeNumericValue(_vm.infos.commissions.autresCommissions))+" ")]),(
                          _vm.infos.commissions.billetterie > 0 ||
                            _vm.infos.commissions.voyagesOrganises > 0 ||
                            _vm.infos.commissions.croisieresToutCompris > 0 ||
                            _vm.infos.commissions.hebergement > 0 ||
                            _vm.infos.commissions.locationAutomobile > 0 ||
                            _vm.infos.commissions.primesAssurance > 0 ||
                            _vm.infos.commissions.autresCommissions
                        )?_c('td',[_vm._v(" 100 % ")]):_c('td',[_vm._v(" 0 % ")])])])])])])],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label-cols-lg":"3","label":"Charges salariales (FCFA)"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0","placeholder":""},model:{value:(_vm.infos.chargesSalariales),callback:function ($$v) {_vm.$set(_vm.infos, "chargesSalariales", _vm._n($$v))},expression:"infos.chargesSalariales"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label-cols-lg":"3","label":"Autres Dépenses (FCFA)"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0","placeholder":""},model:{value:(_vm.infos.autresDepense),callback:function ($$v) {_vm.$set(_vm.infos, "autresDepense", _vm._n($$v))},expression:"infos.autresDepense"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),(_vm.showEditionButtons)?_c('b-row',[_c('b-col',{staticClass:"my-2 text-right",attrs:{"cols":"12"}},[_c('div',{},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Modifier ")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }