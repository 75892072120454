var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"informationsGeneraux"},[_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"12"}},[_c('b-card',[_c('b-card-header',{staticClass:"d-flex justify-content-between"},[_c('h3',[_vm._v("RENSEIGNEMENTS D'EXPLOITATION DU MOIS")])]),_c('hr'),_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('h3',[_vm._v("2- Recettes brutes")])]),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"table-responsive text-center mt-1"},[_c('table',{staticClass:"table table-striped table-bordered"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}}),_c('th',[_vm._v("Montants")]),_c('th',[_vm._v("Pourcentages")])])]),_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Billetterie ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                                  _vm.infos.recettesBrutes.billetterie
                                ),callback:function ($$v) {_vm.$set(_vm.infos.recettesBrutes, "billetterie", _vm._n($$v))},expression:"\n                                  infos.recettesBrutes.billetterie\n                                "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.infos.recettesBrutes.billetterie > 0)?_c('td',[_vm._v(" "+_vm._s((_vm.normalizeNumericValue(_vm.infos.recettesBrutes.billetterie) * 100) / ( _vm.normalizeNumericValue(_vm.infos.recettesBrutes.billetterie) + _vm.normalizeNumericValue(_vm.infos.recettesBrutes.voyagesOrganises) + _vm.normalizeNumericValue(_vm.infos.recettesBrutes.croisieresToutCompris) + _vm.normalizeNumericValue(_vm.infos.recettesBrutes.autresProduits) ))+" % ")]):_c('td',[_vm._v(" 0 ")])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Voyages Organisés ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                                  _vm.infos.recettesBrutes.voyagesOrganises
                                ),callback:function ($$v) {_vm.$set(_vm.infos.recettesBrutes, "voyagesOrganises", _vm._n($$v))},expression:"\n                                  infos.recettesBrutes.voyagesOrganises\n                                "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.infos.recettesBrutes.voyagesOrganises > 0)?_c('td',[_vm._v(" "+_vm._s((_vm.normalizeNumericValue(_vm.infos.recettesBrutes.voyagesOrganises) * 100) / (_vm.normalizeNumericValue(_vm.infos.recettesBrutes.billetterie) + _vm.normalizeNumericValue(_vm.infos.recettesBrutes.voyagesOrganises) + _vm.normalizeNumericValue(_vm.infos.recettesBrutes.croisieresToutCompris) + _vm.normalizeNumericValue(_vm.infos.recettesBrutes.autresProduits)))+" % ")]):_c('td',[_vm._v(" 0 ")])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Croisières Tout Compris ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                                  _vm.infos.recettesBrutes.croisieresToutCompris
                                ),callback:function ($$v) {_vm.$set(_vm.infos.recettesBrutes, "croisieresToutCompris", _vm._n($$v))},expression:"\n                                  infos.recettesBrutes.croisieresToutCompris\n                                "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(
                              _vm.infos.recettesBrutes.croisieresToutCompris > 0
                            )?_c('td',[_vm._v(" "+_vm._s((_vm.normalizeNumericValue(_vm.infos.recettesBrutes.croisieresToutCompris) * 100) / (_vm.normalizeNumericValue(_vm.infos.recettesBrutes.billetterie) + _vm.normalizeNumericValue(_vm.infos.recettesBrutes.voyagesOrganises) + _vm.normalizeNumericValue(_vm.infos.recettesBrutes.croisieresToutCompris) + _vm.normalizeNumericValue(_vm.infos.recettesBrutes.autresProduits)))+" % ")]):_c('td',[_vm._v(" 0 ")])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Autres Produits ")]),_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","min":"0"},model:{value:(
                                  _vm.infos.recettesBrutes.autresProduits
                                ),callback:function ($$v) {_vm.$set(_vm.infos.recettesBrutes, "autresProduits", _vm._n($$v))},expression:"\n                                  infos.recettesBrutes.autresProduits\n                                "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.infos.recettesBrutes.autresProduits > 0)?_c('td',[_vm._v(" "+_vm._s((_vm.normalizeNumericValue(_vm.infos.recettesBrutes.autresProduits) * 100) / (_vm.normalizeNumericValue(_vm.infos.recettesBrutes.billetterie) + _vm.normalizeNumericValue(_vm.infos.recettesBrutes.voyagesOrganises) + _vm.normalizeNumericValue(_vm.infos.recettesBrutes.croisieresToutCompris) + _vm.normalizeNumericValue(_vm.infos.recettesBrutes.autresProduits)))+" % ")]):_c('td',[_vm._v(" 0 ")])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Recettes Brutes Totales ")]),_c('td',[_vm._v(" "+_vm._s(_vm.normalizeNumericValue(_vm.infos.recettesBrutes.billetterie )+ _vm.normalizeNumericValue(_vm.infos.recettesBrutes.voyagesOrganises) + _vm.normalizeNumericValue(_vm.infos.recettesBrutes.croisieresToutCompris )+ _vm.normalizeNumericValue(_vm.infos.recettesBrutes.autresProduits))+" ")]),(
                              _vm.infos.recettesBrutes.billetterie > 0 ||
                                _vm.infos.recettesBrutes.voyagesOrganises > 0 ||
                                _vm.infos.recettesBrutes.croisieresToutCompris >
                                0 ||
                                _vm.infos.recettesBrutes.autresProduits
                            )?_c('td',[_vm._v(" 100 % ")]):_c('td',[_vm._v(" 0 % ")])])])])])])],1)],1)],1)],1)],1),(_vm.showEditionButtons)?_c('b-row',[_c('b-col',{staticClass:"my-2 text-right",attrs:{"cols":"12"}},[_c('div',{},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Modifier ")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }